<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    NEWS_FEEDS_GET_NEWS_FEEDS,
    NEWS_FEEDS_INITIAL_GET_NEWS_FEEDS_STATE
  } from '@/core/store/news-feeds.module';

  export default {
    name: 'CommunityPostList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.post'),
            route: { name: ROUTE_NAME.POST }
          },
          { title: i18nHelper.getMessage('label.postList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.NEWS_FEED,
        actions: {
          read: ACTION.NEWS_FEED_READ
        }
      },
      filters: [
        {
          label: 'owner',
          key: 'owner',
          value: null
        },
        {
          label: 'createdDateRange',
          key: 'createdDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        }
      ],
      actions: {
        viewList: {
          module: 'newsFeeds',
          state: 'newsFeeds',
          action: NEWS_FEEDS_GET_NEWS_FEEDS,
          initialStateAction: NEWS_FEEDS_INITIAL_GET_NEWS_FEEDS_STATE,
          title: i18nHelper.getMessage('label.getCommunityPosts')
        },
        viewDetails: {
          routeName: ROUTE_NAME.COMMUNITY_POST_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'owner',
          name: 'owner',
          active: true,
          sortable: true,
          class: 'min-w-120px'
        },
        {
          key: 'content',
          name: 'content',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_TEXT_TRUNCATE_CONTAINER
        },
        {
          key: 'like',
          name: 'like',
          active: true,
          sortable: true
        },
        {
          key: 'liked',
          name: 'liked',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'comments',
          name: 'comments',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
    })
  };
</script>

<style></style>
